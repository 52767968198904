<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Standaard SSL-certificaat</h1>
        <Price :item="data.staticData.prices.SSLCertificatesPrices[0]"/>
        <h2>Standaard SSL certificaat</h2>
        <div>
          <p>Het standaard SSL certificaat is de ideale keuze voor eenvoudige websites, bijvoorbeeld met een webformulier om contact op te nemen. Bezoekers verzenden dan vertrouwelijke informatie, zoals hun naam, e-mailadres en telefoonnummer. Het is belangrijk en wettelijk verplicht om die informatie via een beveiligde verbinding versleuteld te verzenden. Dit is waar het standaard SSL certificaat voor zorgt.</p>
          <p>
            <img
              class="framed-image"
              style="float: right; margin-left: 20px;"
              src="../../assets/images/sslcertificaat.png"
              height="300"
              width="265"
              title="ssl certificaat kopen, ssl verbinding, wat is ssl, ssl aanvragen"
              alt="ssl certificaat kopen, ssl verbinding, wat is ssl, ssl aanvragen"
            >
            Bovendien zorgt u met een SSL verbinding voor een betrouwbare uitstraling van uw website, dankzij het slotje in de adresbalk op basis van de HTTPS-verbinding in plaats van de reguliere HTTP-verbinding. <i>Let op: een standaard SSL certificaat zorgt niet voor een groene adresbalk en maakt geen bedrijfsgegevens zichtbaar. Hiervoor kunt u het EV-certificaat gebruiken.</i>
          </p>
          <p>Bijkomend voordeel van de SSL verbinding is dat ook Google daar waarde aan hecht, met het oog op de vindbaarheid. De vindbaarheid van uw website in de organische zoekresultaten neemt toe, op het moment dat u een SSL aanvraagt en die gebruikt. U kunt een SSL certificaat kopen om netjes te voldoen aan de voorwaarden voor een veilige verbinding en om te zorgen voor een betrouwbare online uitstraling. Bovendien zorgt u voor een betere vindbaarheid op de belangrijkste zoektermen, dankzij de waardering die Google heeft voor de veilige SSL verbinding.</p>
          <h3>DV-validatie met SSL certificaat</h3>
          <p>De standaard SSL aanvragen? Dit zorgt voor domein validatie (DV). Dat betekent dat bezoekers een slotje in de adresbalk te zien krijgen, dat ze kunnen aanklikken om na te gaan of ze de website bezoeken die ze wilden bezoeken. Hiermee voorkomen bezoekers dat ze een frauduleuze website bekijken, waardoor u zich betrouwbaar kunt opstellen.</p>
          <p>Met een standaard SSL certificaat zorgt u wat dat betreft op twee verschillende manieren voor een goede beveiliging van de samenwerking tussen uw website en de bezoeker die deze bezoekt. De verbinding is 100% veilig en u kunt de betrouwbaarheid uitstralen waar de bezoeker naar op zoek is.</p>
          <p>U kunt het standaard SSL certificaat kopen voor 1 domein, zodat u daar uw eigen website mee kunt beveiligen. Houd er rekening mee dat u een multi-domein of wildcard SSL certificaat nodig heeft om meerdere domeinen te kunnen beveiligen. Het certificaat is standaard 1 jaar geldig. Uiteraard zorgen wij ervoor dat u de geldigheid daarvan vervolgens steeds kunt verlengen, zodat u daar zonder zorgen gebruik van kunt blijven maken.</p>
        </div>
        <h3>100% veilige verbinding</h3>
        <p>Met het SSL certificaat zorgt u voor een 100% veilige verbinding tussen uw website en de bezoeker, zelfs op basis van het meest eenvoudige certificaat. Dat betekent dat u er op een voordelige manier voor kunt zorgen dat u een SSL kunt aanvragen en een SSL certificaat kunt kopen. We maken de verwerking daarvan bovendien direct in orde, zodat u binnen een paar minuten voor een veilige SSL verbinding kunt zorgen.</p>
        <h3>Voordelen van een SSL certificaat bij Hosting on Demand</h3>
        <p>Bij Hosting on Demand profiteert u met een SSL certificaat van:</p>
        <ul>
          <li>Een 100% beveiligde verbinding</li>
          <li>Snelle levering van ieder SSL certificaat</li>
          <li>Betere vindbaarheid in Google</li>
        </ul>
        <p>Het standaard SSL certificaat kopen? U kunt daarmee uw eigen website beveiligen, zodat bezoekers daar zonder problemen gebruik van kunnen maken. De SSL verbinding is 100% veilig en draagt op die manier bij aan de betrouwbare uitstraling van uw organisatie. Het is om die reden een uitstekende keuze voor iedere website.</p>
        <RandomQuote/>
      </div>
      <SSLCertificatesRightSideBlocks/>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Price from '@/components/Price.vue';
import SSLCertificatesRightSideBlocks from '@/components/SSLCertificatesRightSideBlocks.vue';
import RandomQuote from '@/components/RandomQuote.vue';

export default {
  name: 'StandardSSLCertificate',
  components: {
    Breadcrumbs,
    Price,
    SSLCertificatesRightSideBlocks,
    RandomQuote,
  },
  setup() {
    const data = inject('data');

    return {
      // store
      data,
    };
  },
}
</script>